import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../layouts/Index'
import Style from '../styles/pages/contact.module.scss'
import SEO from '../components/SEO'
import Popover from '@material-ui/core/Popover'
import Parse from 'parse'
import * as EmailValidator from 'email-validator'
import { NotificationContainer, NotificationManager } from 'react-notifications'

class Code extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      anchorEl: null,
      id: null,
      code_parts: [],
      loading: false
    }
    
    const { PARSE_SERVER_URL, PARSE_APP_ID, PARSE_JAVASCRIPT_KEY } = props.pageContext
    Parse.initialize(PARSE_APP_ID, PARSE_JAVASCRIPT_KEY);
    Parse.serverURL = PARSE_SERVER_URL;

    this.Parse = Parse;
    this.stories = {
      "0003": {
        title: {
          fr: "Pink Rose - Drôle de rencontre",
          en: "Pink Rose - The British Puppet"
        }
      },
      "0005": {
        title: {
          fr: "Les Bras Cassés",
          en: "Helping Hands"
        }
      },
      "0010": {
        title: {
          fr: "Awa et l'oiseau aux instruments"
        }
      },
      "0011": {
        title: {
          fr: "Le Père Noël est en retard",
          en: "Santa Claus is late"
        }
      },
      "0028": {
        title: {
          fr: "Essi dans la forêt des monstres"
        }
      }
    };
    this.code_parts = []
  }

  componentDidMount() {
    
    const { location } = this.props;
    var query = location.search.substring(1);
    var vars = query.split('&');
    var values = [];
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      if (decodeURIComponent(pair[0]) === 'key') {
        values.push(decodeURIComponent(pair[1]));
      }
    }
    if (values.length === 1) {
      this.setState({ code_parts: values[0].split('-') })
    }
  }

  setAnchorEl = (e) => {
    this.setState({ anchorEl: e, id: e && e.dataset.id})
  }

  handleClick = (e) => {
    e.preventDefault()
    this.setAnchorEl(e.currentTarget);
  };

  handleClose = (e) => {
    e.preventDefault()
    this.setAnchorEl(null);
  };

  codeChange = (e) => {
    if(e.target.value.length === 4) {
      if (parseInt(e.target.dataset.partid) < 3) {
        document.getElementById(`inputCodePart${parseInt(e.target.dataset.partid)+1}`).focus()
      }
    }
  }

  handleSendCode = (e) => {
    e.preventDefault();
    const formData = {
      'email': e.target.elements.email.value.trim(),
      'code_part_0': e.target.elements.code_part_0.value.trim().toUpperCase(),
      'code_part_1': e.target.elements.code_part_1.value.trim().toUpperCase(),
      'code_part_2': e.target.elements.code_part_2.value.trim().toUpperCase(),
      'code_part_3': e.target.elements.code_part_3.value.trim().toUpperCase(),
    }
    
    if (!EmailValidator.validate(formData.email)) {
      NotificationManager.error(this._t('Veuillez saisir une adresse E-mail valide.'), 'erreur', 5000);
      return false;
    }
    
    if (formData.code_part_0.length < 4 || formData.code_part_1.length < 4 || formData.code_part_2.length < 4 || formData.code_part_3.length < 4) {
      NotificationManager.error('Votre code est incorrect', 'erreur', 5000);
      return false;
    }
    const code = `${formData.code_part_0}-${formData.code_part_1}-${formData.code_part_2}-${formData.code_part_3}`;
    let regex = new RegExp('^([A-Z0-9]{4})-([A-Z0-9-]{4})-([A-Z0-9-]{4})-([A-Z0-9-]{4})$');
    let is_code_format_correct = regex.test(code);
    if (!is_code_format_correct) {
      NotificationManager.error('Votre code est incorrect', 'erreur', 5000);
      return false;
    }
    const params = { email: formData.email, key: code }
    const _self = this;
    
    this.setState({ loading: true })
    
    try {
      _self.Parse.Cloud.run("activate_story", params).then(function (response) {
        if (response.error) {
          console.log(134, response.error)
          _self.setState({ loading: false })
          return _self.handleActivationError(response.error)
        }
        _self.handleActivationSuccess(response.data);
        _self.setState({ loading: false })
      }, function (error) {
        console.log(141, error)
        _self.handleActivationError(error.toString());
        _self.setState({ loading: false })
      });
    } catch (e) {
      console.log(146, 'error')
      _self.handleActivationError(e.toString())
      _self.setState({ loading: false })
    }
  }

  handleActivationSuccess = (data) => {
    var alert_title = 'Super, votre livre a été activé'; 
    var alert_message = `Pour que cela soit bien pris en compte sur votre application : ouvrez l’appli Wakatoon, et dans la section 'Mon Compte' connectez-vous avec votre compte Wakatoon puis tapez sur 'RÉCUPÉRER MES HISTOIRES'. Joyeux coloriages animés !`	
    var story_title = this.stories[data.story_id] ? this.stories[data.story_id].title.fr : "";
    if (story_title) {
      alert_title = `Super, votre livre "${story_title}" a été activé !`
    }
    NotificationManager.success(alert_message, alert_title, 60000);
  }

  handleActivationError = (error) => {
    var alert_title = 'Votre livre n’a pu être activé !'
    var alert_message = '';

    switch (error) {

      case 'ERROR_EMAIL_NOT_VERIFIED':
      alert_title = "Erreur : Email non vérifié";
      alert_message = "Votre livre n’a pu être activé car votre compte Wakatoon existe mais il n’est pas vérifié. Vous devez cliquer sur le lien de vérification qui est inclus dans l’email de confirmation que vous avez reçu suite à la création de votre compte. Vous ne retrouvez pas cet email de confirmation ? Ouvrez l’application Wakatoon et dans la section 'Mon Compte' demandez à le recevoir à nouveau. Toujours bloqué(e) ? Ecrivez-nous à l’adresse help@wakatoon.com";
      break;

      case 'INFO_CODE_ALREADY_ASSOCIATED_TO_USER':
      alert_title = "Info : Livre déjà activé";
      alert_message = "Ce livre est déjà activé pour votre compte Wakatoon. Pour que cela soit bien pris en compte dans votre application : ouvrez l’appli Wakatoon, et dans la section 'Mon Compte' connectez-vous avec votre compte Wakatoon puis tapez sur 'RÉCUPÉRER MES HISTOIRES'";
      break;

      case 'ERROR_CODE_ALREADY_ASSOCIATED_TO_ANOTHER_USER' :
      alert_title = "Erreur : Code déjà utilisé";
      alert_message = "Votre livre n’a pu être activé pour votre compte car il a déjà été activé pour autre compte Wakatoon que le vôtre. Il est possible qu’une personne de votre entourage ait déjà activé votre livre. Pouvez-vous mener une petite enquête autour de vous ? Vous n’avez pas trouvé ? Ecrivez-nous à l’adresse help@wakatoon.com pour que l’on vous aide";
      break;

      case 'ERROR_CODE_NOT_FOUND':
      alert_title = "Erreur : code introuvable";
      alert_message = "Votre livre n'a pu être activé car le code que vous avez saisi ne correspond à aucun code Wakatoon existant. Pas de panique ! Il est possible que vous ayez fait une faute de frappe lors de la saisie du code. Pouvez-vous vérifier que vous avez bien saisi le code inscrit sur la toute première page du livre ? Si cela ne fonctionne toujours pas, envoyez-nous une photo de la couverture du livre ainsi qu’une photo de la page contenant le code à l’adresse help@wakatoon.com";
      break;

      case 'ERROR_USER_NOT_FOUND':
      alert_title = "Erreur : Utilisateur introuvable";
      alert_message = "Votre livre n’a pu être activé car l’email que vous avez saisi ne correspond à aucun compte Wakatoon existant. Pas de panique ! Soit vous avez saisi votre email avec une erreur, soit vous n'avez pas encore créé votre compte Wakatoon. Pour cela, installez puis ouvrez l’appli Wakatoon sur votre téléphone ou votre tablette, allez dans la section 'Mon Compte' et créez votre compte. Une fois votre compte créé, revenez sur cette page pour activer votre livre.";
      break;

      case 'ERROR_WHILE_SEARCHING_CODE':
      case 'ERROR_WHILE_ASSOCIATING_CODE_TO_USER' :
      case 'ERROR_WHILE_SEARCHING_USER' :
      alert_title = `Erreur`;
      alert_message = `Une erreur est survenue (${error}). Merci de réessayer un peu plus tard. Le problème persiste ? Ecrivez-nous à l'adresse help@wakatoon.com en indiquant le code de votre livre.`;
      break;

      default:
      alert_title = `Erreur`;
      alert_message = `Une erreur est survenue (${error}). Merci de réessayer un peu plus tard. Le problème persiste ? merci de nous écire à l'adresse help@wakatoon.com en indiquant le code de votre livre.`;
      break;
    }
    NotificationManager.error(alert_message, alert_title, 20000);
  }

  _t = (str) => {
    const _t = this.props.data.staticText.edges[0].node.data.texts;
    return _t[_t.findIndex(item => item.key_text === str)].text.text;
  };

  render() {
    const {
      data: { staticText }
    } = this.props;

    const staticPrismicTexts = staticText.edges[0].node.data.texts;
    const isPrefilled = true;
    const is_code_format_correct = true;
    const inputs = [0, 1, 2, 3]
    let code_parts = [{},{},{},{}];
    
    for(var i=0; i<4; i++) {
      code_parts[i]["hasBeenfilledOnce"] = isPrefilled;
    }
    if (!isPrefilled) {
      document.getElementById('inputCodePart0').focus()
    }
    
    return (
      <Layout
        pageTitle={'Wakatoon'} 
        context={this.props.pageContext}
        _t={staticPrismicTexts}
      >
        <SEO 
          pageTitle={'Wakatoon'} 
          context={this.props.pageContext} 
        />
        <div className={`page codepage"`}>
          <section style={{padding: 0, minHeight: 150}}>
            <div className="page-head">
              <h2>Activer un livre Wakatoon</h2>
            </div>
          </section>
          <section>
            <div className="container container-small">
              <div className={`${Style.row} row`}>
                <div className="col-12" style={{padding: 0}}>
                  <form onSubmit={this.handleSendCode} id="contact-form">
                    
                    <div className={`${Style.row} row`}>
                      <div className="col-xs-12 col-md-8">
                        {!is_code_format_correct && (
                          <div className="alert alert-danger" role="alert">
                            Ce lien est incorrect. Si vous cherchez à activer une histoire, suivez les instructions ci-dessous.
                          </div>
                        )}
                      </div>
                    </div>

                    <div className={`${Style.row} row`}>
                      <div className="col-12 centered">
                        <p className="lead">Pour pouvoir wakatooner* votre livre, vous devez disposer d'un compte Wakatoon et y associer le code unique de votre livre</p>
                      </div>
                    </div>

                    <div className={`${Style.row} row`} style={{maxWidth: '425px', margin: '0 auto'}}>
                      {
                        inputs.map((i) => {
                          return (
                            <div className="form-group col-3 codeparts" style={{ marginBottom: '0px'}} key={`codepart-${i}`}>
                              <input 
                                style={{display: 'inline-block'}} 
                                type="text" 
                                name={`code_part_${i}`} 
                                className="form-control fit-4-digit force-inline-block text-uppercase" 
                                maxLength="4" 
                                placeholder="XXXX" 
                                id={`inputCodePart${i}`} 
                                data-partid={i}
                                onChange={this.codeChange}
                                defaultValue={this.state.code_parts[i]}
                              />
                            </div>
                          )
                        })
                      }
                    </div>
                    
                    <p style={{textAlign: 'center'}}>
                      <button style={{border: 0, marginTop: '5px'}} data-id="describe-code" onClick={this.handleClick}>
                        <small><em>Quel code saisir ?</em></small>
                      </button>
                    </p>
                    <Popover
                      id="describe-code"
                      open={this.state.id === "describe-code"}
                      anchorEl={this.state.anchorEl}
                      onClose={this.handleClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                      <p style={{padding: '15px 15px 0 15px', maxWidth: '280px'}}>
                        Le code se trouve sur la toute première page de votre livre, celle qui précède le premier coloriage. Il est composé de 16 caractères.
                      </p>
                    </Popover>

                    <div className="form-group">
                      <div className={`${Style.row} row`} style={{ maxWidth: '320px', margin: '0 auto'}}>
                        <div className={`${Style.col6} col-12 centered`}>
                          <label htmlFor="email">Email de votre compte Wakatoon</label>
                          <input type="email" className="form-control" id="email" name="email" placeholder="E-mail" />
                        </div>
                      </div>
                    </div>
                    <p style={{textAlign: 'center'}}>
                      <button style={{border: 0, marginTop: '5px'}} data-id="describe-account" onClick={this.handleClick}>
                        <small><em>Pas de compte Wakatoon ?</em></small>
                      </button>
                    </p>
                    <Popover
                      id="describe-account"
                      open={this.state.id === "describe-account"}
                      anchorEl={this.state.anchorEl}
                      onClose={this.handleClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                      <p style={{ padding: '15px 15px 0 15px', maxWidth: '280px' }}>
                        Pour créer un compte Wakatoon: installez et ouvrez l’appli Wakatoon sur téléphone ou tablette, puis allez dans la section "Mon Compte"
                      </p>
                    </Popover>

                    <div className="form-group centered">
                      <div className={`${Style.row} row`}>
                        <div className="col-12">
                          <button 
                            type="submit" 
                            className="button btn btn_default" 
                            id="buttonActivate"
                            disabled={this.state.loading}
                          >
                            {this.state.loading ? 'Vérification...' : 'Activer le livre'}
                          </button>
                        </div>
                      </div>
                    </div>
                    <p style={{ textAlign: 'center' }}><small><em><span>Un souci ? Ecrivez-nous à</span><span>{` `}</span><a href="mailto:help@wakatoon.com">help@wakatoon.com</a><span>{` `}</span></em></small></p>
                  </form>
                </div>
              </div>
              <p style={{textAlign: 'justify', lineHeight: '18px'}}>
                <small>
                  * En tant que possesseur d’un livre Wakatoon, vous n’avez aucun achat supplémentaire à effectuer pour pouvoir en transformer l'intégralité des pages de ce livre en dessin animé. Donc si malgré l'activation de votre livre l’application Wakatoon vous demande d’effectuer un achat supplémentaire, contactez-nous à help@wakatoon.com
                </small>
              </p>
            </div>
          </section>
          <NotificationContainer />
        </div>
      </Layout>
    )
  }
}

export default Code

export const pageQuery = graphql`
  query codePage($locale: String!) {
    staticText: allPrismicGlobalStatictexts(filter: { lang: { eq: $locale} }) {
      edges { ...StaticTexts }
    }
  }
`;